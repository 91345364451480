import * as React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import {
    formatAmountBrl,
    formatOnlyDigits,
    formatPhone,
    formatUsingMask,
    removeTrailingNonDigits,
} from '../utils/format';
import { roundTwoPlaces } from '../utils/number';
import { isCpfValid } from '../utils/validation/cpf';
import { isPhoneValid } from '../utils/validation/phone';
import { Input, LogoContainer, SubmitButton, Title, VendahLogo } from '../components';
import { Container, InputsContainer, SubTitleContainer, TitleContainer } from './styles';
import AddressForm, { Address } from '../components/AddressForm';
import { Button } from '../components/Button';
import { BLUE_PRIMARY_COLOR } from '../style';
import { useLocalStorageState, useLocalStorageStateObj } from '../utils/useLocalStorage';
import { useAsyncEffect } from '../hook/useAsyncEffect';
import { getShippingInfo, ShippingInfoResponse } from '../client/vendah/orderPaymentService/shipping-info';
import FreeShippingCard from '../components/FreeShippingCard';
import { useParams } from 'react-router-dom';
import {
    CheckoutData,
    LineItemValidateRequest,
    ResellerCheckoutData,
    validateCheckout,
    Variant,
} from '../client/vendah/orderPaymentService/validate-checkout';
import {
    findConectahReseller,
    getResellerByPhone,
    ResellerPhoneResponse,
    ResellerReponse,
} from '../client/vendah/orderPaymentService/reseller';
import {
    createOrder,
    CreateOrderRequest,
    CreateOrderResponse,
    Discount,
    LineItem,
} from '../client/vendah/orderPaymentService/orders';
import { set } from 'husky';

const ResellerNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    padding: 16px;
`;

const ShippingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 16px;
    line-height: 14px;

    text-align: left;
`;

const PriceContainer = styled.div`
    white-space: nowrap;
`;

const LineItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const LineItemTitleContainer = styled.div`
    display: flex;
    text-align: left;
    align-items: center;
    img {
        width: 32px;
        height: 32px;

        margin-right: 16px;
    }
`;

const ShippingOptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 16px;
`;

const CostContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 16px;
`;

const DiscountContainer = styled.div`
    display: flex;
    color: ${BLUE_PRIMARY_COLOR};
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 16px;
`;

const LineItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 0px 16px;
`;

const TotalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-weight: 700;
    font-size: 16px;

    padding: 6px 16px;
`;

const PriceSummaryContainer = styled.div`
    padding-top: 10px;
    margin-top: 10px;
    background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #ffffff;
`;

const ShippingDelayWarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    font-size: 12px;
    padding: 0px 16px 6px 16px;
`;

const Form = styled.form``;

function CheckoutPage() {
    const [submitInProgress, setSubmitInProgress] = React.useState(false);
    const [error, setError] = React.useState<Error>();
    const [shipToReseller, setShipToReseller] = React.useState(true);
    const [name, setName] = useLocalStorageState('checkout.name');
    const [cpf, setCpf] = useLocalStorageState('checkout.cpf');
    const [phone, setPhone] = useLocalStorageState('checkout.phone');
    const [resellerPhone, setResellerPhone] = React.useState('');
    const [address, setAddress] = useLocalStorageStateObj<Address>('checkout.address', {
        postalCode: '',
        streetAddress: '',
        streetNumber: '',
        complement: '',
        reference: '',
        neighborhood: '',
        city: '',
        stateCode: '',
    });
    const [invalidAddress, setInvalidAddress] = React.useState(false);
    const [postalCodeInvalidMessage, setPostalCodeInvalidMessage] = React.useState<string>();
    const [resellerPhoneInvalidMessage, setResellerPhoneInvalidMessage] = React.useState<string>();
    const [arrivingSoonMessage, setArrivingSoonMessage] = React.useState<string>();
    const [arrivingSoonWorkdays, setArrivingSoonWorkdays] = React.useState(0);

    const nameInvalid = React.useMemo(() => name.split(' ').filter(name => name.trim().length > 0).length < 2, [name]);
    const customerTaxIdInvalid = React.useMemo(() => !isCpfValid(cpf), [cpf]);
    const phoneInvalid = React.useMemo(() => !isPhoneValid(phone), [phone]);
    const resellerPhoneInvalid = React.useMemo(() => !isPhoneValid(resellerPhone), [resellerPhone]);
    const [loadingShipping, setLoadingShipping] = React.useState(false);
    const [shippingInfo, setShippingInfo] = React.useState<ShippingInfoResponse>();
    const [reseller, setReseller] = React.useState<ResellerCheckoutData | undefined>();
    const [lineItems, setLineItems] = React.useState<Variant[]>();
    const [alreadyHasReseller, setAlreadyHasReseller] = React.useState(false);
    const [isConectah, setConectah] = React.useState(false);
    const postalCode = address.postalCode;

    const { resellerId, items } = useParams();

    React.useEffect(() => {
        if (isConectah) setShipToReseller(false);
        if (reseller && !reseller.can_ship_to_reseller) setShipToReseller(false);
    }, [isConectah, reseller, reseller?.can_ship_to_reseller]);

    const shipmentWorkdays = shipToReseller
        ? shippingInfo?.ship_to_reseller_workdays
        : shippingInfo?.ship_to_customer_workdays;

    console.log('invalidAddress', invalidAddress);

    useAsyncEffect(
        async () => {
            if (!items) return undefined;

            const lineItems: LineItemValidateRequest[] = [];
            for (const itemStr of items.split(',')) {
                const [variantId, quantity] = itemStr.split(':');
                lineItems.push({
                    variant_id: variantId,
                    quantity: parseInt(quantity || '1'),
                });
            }

            try {
                const response: CheckoutData = await validateCheckout({
                    reseller_id: resellerId,
                    line_items: lineItems,
                });

                window.dataLayer.push({
                    event: 'begin_checkout',
                    variants: response,
                });
                return response;
            } catch (error) {
                setError(new Error('Erro ao procurar produtos!'));
            }
        },
        checkoutData => {
            if (!checkoutData) return;

            let lineItems = checkoutData.line_items;
            const arrivingSoonItems = checkoutData.line_items.filter(
                lineItem => lineItem.quantity_available > 0 && lineItem.quantity > lineItem.quantity_available - lineItem.arriving_soon_config.quantity,
            );
            if (arrivingSoonItems.length > 0) {
                if (
                    checkoutData.line_items.length !== arrivingSoonItems.length ||
                    !arrivingSoonItems.every(item => item.arriving_soon_config.date === arrivingSoonItems[0].arriving_soon_config.date)
                ) {
                    setArrivingSoonMessage(`Seu carrinho tinha produtos com prazos diferentes. Os seguintes itens foram retirados: ${arrivingSoonItems.map(item => `${item.title}`)}`);
                    lineItems = lineItems.filter(lineItem => !arrivingSoonItems.includes(lineItem));
                }
            }

            setReseller(checkoutData.reseller);
            setLineItems(lineItems);
        },
        []
    );

    useAsyncEffect(
        async () => {
            setPostalCodeInvalidMessage(undefined);

            const clearPostalCode = formatOnlyDigits(postalCode);
            if (!shipToReseller) {
                if (!postalCode) return undefined;

                if (clearPostalCode.length !== 8) {
                    return undefined;
                }
            }
            if (!reseller) return undefined;

            setLoadingShipping(true);

            console.log('calling getShippingInfo', postalCode);
            setShippingInfo(undefined);
            try {
                const shippingInfo = await getShippingInfo({
                    reseller_id: reseller.id,
                    postal_code: shipToReseller ? undefined : postalCode,
                    auto_checkout: true,
                });
                if (!shippingInfo.success) {
                    const errorMessage = shippingInfo.error ?? 'Erro ao calcular o Frete';
                    setPostalCodeInvalidMessage(errorMessage);
                    return undefined; // must not set the unsuccessful shippingInfo (its type assumes successful)
                }

                if (isConectah && !isSPPostalCode(clearPostalCode)) {
                    setPostalCodeInvalidMessage('Não é possível entregar para esse endereço'); // Conectah v1 MVP restriction
                }

                return shippingInfo;
            } catch (error) {
                Sentry.captureException(error);
                setError(new Error('Erro ao calcular o Frete'));
                return undefined;
            } finally {
                setLoadingShipping(false);
            }
        },
        setShippingInfo,
        [postalCode, shipToReseller, reseller, isConectah]
    );

    useAsyncEffect(
        async () => {
            if (!resellerPhone) return;

            const clearResellerPhone = formatOnlyDigits(resellerPhone);
            if (clearResellerPhone.length !== 11) {
                if (clearResellerPhone.length > 0) {
                    setResellerPhoneInvalidMessage('O Celular precisa ter 11 dígitos');
                }
                return;
            }
            setResellerPhoneInvalidMessage(undefined);

            try {
                const response: ResellerPhoneResponse = await getResellerByPhone(clearResellerPhone);

                if (response.success && response.availability === 'IN_USE') {
                    console.log('Found reseller by phone', response);
                    return {
                        id: response.reseller_id,
                        first_name: response.first_name || '',
                        custom_name: response.custom_name,
                        can_ship_to_reseller: response.can_ship_to_reseller,
                    };
                }
                setResellerPhoneInvalidMessage('Revendedora não encontrada com esse celular');
                return undefined;
            } catch (error) {
                Sentry.captureException(error);
                setError(new Error('Erro ao encontrar a revendedora'));
                return undefined;
            }
        },
        setReseller,
        [resellerPhone]
    );

    function isSPPostalCode(clearPostalCode: string) {
        const cleanPostalCode = parseInt(clearPostalCode);
        return cleanPostalCode >= 1000000 && cleanPostalCode <= 19999999;
    }

    function getShippingFee(totalSuggestedPrice: number, shippingInfo?: ShippingInfoResponse) {
        if (!shippingInfo) return { fee: undefined, priceToFreeShipping: 0 };

        const priceToFreeShipping = shipToReseller
            ? shippingInfo.price_to_reseller_free_shipping
            : shippingInfo.price_to_customer_free_shipping;
        if (totalSuggestedPrice >= priceToFreeShipping) return { fee: 0, priceToFreeShipping };

        const shippingFee = shipToReseller ? shippingInfo.ship_to_reseller_fee : shippingInfo.ship_to_customer_fee;
        return { fee: shippingFee, priceToFreeShipping };
    }

    function splitName(name: string): [string, string] {
        const index = name.indexOf(' ');
        return [name.slice(0, index).trim(), name.slice(index + 1, name.length).trim()];
    }

    function convertLineItems(cartItems: Variant[]): LineItem[] {
        return cartItems.map((cartItem): LineItem => {
            const cost = parseFloat(cartItem.cost);
            const price = parseFloat(cartItem.price);
            return {
                variant_id: parseInt(cartItem.id),
                quantity: cartItem.quantity,
                unit_price: roundTwoPlaces(cost),
                unit_discount: 0,
                total_commission: roundTwoPlaces((price - cost) * cartItem.quantity),
                discount_name: undefined,
            };
        });
    }

    async function findReseller() {
        try {
            const response: ResellerReponse = await findConectahReseller();

            console.log('findReseller response', response);
            setConectah(true);
            setShipToReseller(false);
            setReseller({
                id: response.reseller_id,
                first_name: response.first_name || '',
                custom_name: response.custom_name,
                can_ship_to_reseller: response.can_ship_to_reseller,
            });
        } catch (error) {
            Sentry.captureException(error);
            setError(new Error('Erro ao procurar um revendedora'));
            return undefined;
        }
    }

    async function submitForm(evt: any) {
        evt.preventDefault();
        if (!submitEnabled) return;
        if (!reseller) return;
        if (!lineItems) return;

        if (submitInProgress) {
            console.log('Ignoring double submit');
            return;
        }
        setSubmitInProgress(true);

        const [firstName, lastName] = splitName(name);

        const request: CreateOrderRequest = {
            seller_id: parseInt(reseller.id),
            need_to_set_address: false,
            customer_first_name: firstName,
            customer_last_name: lastName,
            customer_phone: phone,
            ship_to_seller: shipToReseller,
            line_items: convertLineItems(lineItems),
            discount: discount,
            shipping_fee: shipping.fee,
            auto_checkout: true,
            tags: isConectah ? 'conectah' : undefined,
        };

        if (!shipToReseller) request.customer_tax_id = cpf;

        if (!shipToReseller) {
            request.shipping_address = {
                postal_code: address.postalCode,
                street_address: address.streetAddress,
                street_number: address.streetNumber,
                complement: address.complement,
                reference: address.reference,
                neighborhood: address.neighborhood,
                city: address.city,
                state_code: address.stateCode,
                phone: phone,
            };
        }

        try {
            console.log('create order request', request);
            const response: CreateOrderResponse = await createOrder(request);

            console.log('create order response', response);
            if (response.success) {
                window.location.replace(
                    `https://vendah.com.br/pages/pedido?id=${response.hash}&r=${reseller.custom_name}`
                );
            } else {
                setError(new Error(response.error || 'Erro ao processar seu pedido'));
            }
        } catch (error: any) {
            Sentry.captureException(error);
            setError(new Error('Erro ao processar seu pedido'));
        }
    }

    if (!lineItems) return null;

    function priceFormat(value: number | undefined): string {
        if (value === undefined) return 'A calcular';

        if (value === 0) return 'Grátis';

        return formatAmountBrl(value);
    }

    const subtotal = roundTwoPlaces(
        lineItems.map(item => parseFloat(item.price) * item.quantity).reduce((sum, val) => sum + val, 0)
    );
    const shipping = getShippingFee(subtotal, shippingInfo);
    const discount: Discount | undefined = computeDiscount();
    const cartDiscount = discount ? discount.amount : 0;

    function computeDiscount(): Discount | undefined {
        const now = new Date();
        if (now.getFullYear() !== 2024 || now.getMonth() !== 8 || (now.getDate() !== 21 && now.getDate() !== 22))
            return undefined;
        const discountAmount = computeCartDiscount();
        if (!discountAmount) return undefined;
        return { name: 'Especial Cliente', amount: discountAmount };
    }

    function computeCartDiscount(): number {
        if (subtotal >= 200) return 15;
        if (subtotal >= 150) return 10;
        if (subtotal >= 80) return 5;
        return 0;
    }

    const total = subtotal + (shipping.fee || 0) - cartDiscount;
    const submitEnabled =
        reseller &&
        name &&
        lineItems &&
        !submitInProgress &&
        !nameInvalid &&
        (!customerTaxIdInvalid || shipToReseller) &&
        !phoneInvalid &&
        (shipToReseller || !invalidAddress) &&
        shipping.fee !== undefined;

    const isFreeShippingToReseller = shippingInfo && subtotal >= shippingInfo.price_to_reseller_free_shipping;

    console.log('reseller', reseller);

    if (error) throw error;

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <TitleContainer>
                <Title>Resumo do pedido</Title>
            </TitleContainer>
            {arrivingSoonMessage &&
                <strong>{arrivingSoonMessage}</strong>
            }
            <SubTitleContainer>
                <Title>Seus Dados</Title>
            </SubTitleContainer>
            <Form onSubmit={submitForm}>
                <InputsContainer>
                    <Input
                        id="name"
                        value={name}
                        label="Nome Completo"
                        onValueChange={setName}
                        placeholder="Joana da Silva"
                        invalidMessage={nameInvalid ? 'Preencher o nome completo' : undefined}
                    />
                    {!shipToReseller && (
                        <Input
                            id="cpf"
                            value={cpf}
                            label="CPF"
                            placeholder="000.000.000-00"
                            onValueChange={text => {
                                setCpf(removeTrailingNonDigits(formatUsingMask(text, '999.999.999-99')));
                            }}
                            invalidMessage={customerTaxIdInvalid ? 'CPF inválido' : undefined}
                        />
                    )}
                    <Input
                        id="phone"
                        value={phone}
                        label="Celular"
                        placeholder="(11) 99999-9999"
                        onValueChange={text => {
                            setPhone(removeTrailingNonDigits(formatPhone(text)));
                        }}
                        invalidMessage={phoneInvalid ? 'Celular inválido' : undefined}
                    />
                </InputsContainer>
                {!isConectah && reseller?.can_ship_to_reseller && (
                    <>
                        <SubTitleContainer>
                            <Title>Como deseja receber?</Title>
                        </SubTitleContainer>
                        <ShippingOptionsContainer>
                            <Button selected={shipToReseller} onPress={() => setShipToReseller(true)}>
                                <div>
                                    <strong>Retirar{isFreeShippingToReseller ? ' grátis' : ''}</strong> com{' '}
                                    {reseller ? reseller.first_name : 'Revendedora'}
                                </div>
                            </Button>
                            <Button selected={!shipToReseller} onPress={() => setShipToReseller(false)}>
                                <div>
                                    <strong>Receber</strong> no meu endereço
                                </div>
                            </Button>
                        </ShippingOptionsContainer>
                    </>
                )}
                {reseller && (
                    <ResellerNameContainer>
                        <strong>Vendido por</strong>
                        <p>{reseller.first_name}</p>
                    </ResellerNameContainer>
                )}
                {!reseller && (
                    <>
                        <ResellerNameContainer>
                            <strong>Vendido por</strong>
                            <p>Nenhuma revendedora</p>
                        </ResellerNameContainer>
                        <ShippingOptionsContainer>
                            <Button selected={true} onPress={() => setAlreadyHasReseller(true)}>
                                <div>
                                    Já <strong>tenho</strong> uma revendedora
                                </div>
                            </Button>
                            <Button selected={true} onPress={findReseller}>
                                <div>
                                    <strong>Preciso</strong> de uma revendedora
                                </div>
                            </Button>
                        </ShippingOptionsContainer>
                        {alreadyHasReseller && (
                            <InputsContainer>
                                <Input
                                    id="phone"
                                    value={resellerPhone}
                                    label="Celular da Revendedora"
                                    placeholder="(11) 99999-9999"
                                    onValueChange={text => {
                                        setResellerPhone(removeTrailingNonDigits(formatPhone(text)));
                                    }}
                                    invalidMessage={
                                        resellerPhoneInvalid ? 'Celular inválido' : resellerPhoneInvalidMessage
                                    }
                                />
                            </InputsContainer>
                        )}
                    </>
                )}
                {!shipToReseller && (
                    <>
                        <SubTitleContainer>
                            <Title>Endereço para entrega</Title>
                        </SubTitleContainer>
                        <AddressForm
                            address={address}
                            onChangeAddress={(fieldsToUpdate: Partial<Address>) => {
                                if (fieldsToUpdate.postalCode) {
                                    fieldsToUpdate.postalCode = removeTrailingNonDigits(
                                        formatUsingMask(fieldsToUpdate.postalCode, '99999-999')
                                    );
                                }
                                setAddress(prevState => ({ ...prevState, ...fieldsToUpdate }));
                            }}
                            onChangeInvalid={setInvalidAddress}
                            invalidMessage={postalCodeInvalidMessage}
                        />
                    </>
                )}
                <SubTitleContainer>
                    <Title>Detalhes do pedido</Title>
                </SubTitleContainer>
                <LineItemsContainer>
                    {lineItems.map((item, index) => (
                        <LineItemContainer key={index}>
                            <LineItemTitleContainer>
                                <img src={item.image_url} alt="" />
                                <p>
                                    {item.quantity} × {item.title}
                                </p>
                            </LineItemTitleContainer>
                            <PriceContainer>{formatAmountBrl(parseFloat(item.price) * item.quantity)}</PriceContainer>
                        </LineItemContainer>
                    ))}
                </LineItemsContainer>
                <FreeShippingCard price={subtotal} priceToFreeShipping={shipping.priceToFreeShipping} />
                <PriceSummaryContainer>
                    <CostContainer>
                        <div>Subtotal</div>
                        <div>{formatAmountBrl(subtotal)}</div>
                    </CostContainer>
                    <CostContainer>
                        <div>
                            Frete
                            {shippingInfo && shipmentWorkdays && ` (${shipmentWorkdays} dias úteis)`}
                        </div>
                        <div>{loadingShipping ? 'Calculando...' : priceFormat(shipping.fee)}</div>
                    </CostContainer>
                    {shippingInfo?.has_delay && (
                        <ShippingDelayWarningContainer>
                            <div>Nossos prazos estão temporariamente acima do normal</div>
                        </ShippingDelayWarningContainer>
                    )}
                    {discount && (
                        <DiscountContainer>
                            <div>Desconto {discount.name}</div>
                            <div>- {formatAmountBrl(discount.amount)}</div>
                        </DiscountContainer>
                    )}
                    <TotalContainer>
                        <div>Total</div>
                        <div>{formatAmountBrl(total)}</div>
                    </TotalContainer>
                    <SubmitButton disabled={!submitEnabled}>
                        <Title>{submitInProgress ? 'Carregando...' : 'Criar pedido'}</Title>
                    </SubmitButton>
                </PriceSummaryContainer>
            </Form>
            {shipToReseller && shipmentWorkdays !== undefined && (
                <ShippingContainer>
                    <strong>Forma de entrega</strong>
                    <p>
                        Retire com {reseller ? reseller.first_name : 'Revendedora'} em até {shipmentWorkdays} dias úteis
                        após o pagamento
                    </p>
                </ShippingContainer>
            )}
        </Container>
    );
}

export default CheckoutPage;
